.btn,
.jumbotron,
.nav :hover {
  border-radius: 0px;
}

body {
  background: #343a40;
}

.custom-transparent-list-info > li {
  background: rgb(0, 0, 0, 0.2);
  border-color: #5bc0de;
}

.custom-transparent-admin-dashboard-list > li {
  background: rgb(0, 0, 0, 0);
  /* border-color: #5bc0de; */
}

.bg-custom {
  background: #2d3136;
}

/* footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
} */
